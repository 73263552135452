<template>
    <div>
      <client-responsible-report-list></client-responsible-report-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  